/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-21 14:52:07
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-07-12 09:40:02
 * @FilePath: \qctsw-vue\packages\app\src\server\modules\ad\types.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum AD_KEY {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A6 = 'A6',
  A7 = 'A7',
  A8 = 'A8',
  A9 = 'A9',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  D1 = 'D1',
  D2 = 'D2',
  D3 = 'D3',
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
}

export type AD = Partial<Record<keyof typeof AD_KEY, IAdResponse[]>>
export type AD_KEY_TYPE = keyof typeof AD_KEY
export interface IAdResponse {
  'imgUrl': string
  'imgWidth': string
  'linkUrl': string
  'imgHeight': string
  'id': number
  'adShowLocationId': number
  'adShowLocationCode': `${AD_KEY}`,
  adClientTypeCode:1|2,
  title:string;
}
