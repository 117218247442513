<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-21 15:20:17
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-07-12 16:18:00
 * @FilePath: \qctsw-vue\packages\app\src\components\base\ad\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-21 15:20:17
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-22 09:11:54
 * @FilePath: \qctsw-vue\packages\app\src\components\base\ad\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script lang='ts' setup>
import { APP_ROUTER, imageTransform } from '@qctsw/common'
import adStore from '@/stores/ad'
import { AD_KEY, type AD_KEY_TYPE } from '~/server/types'

const props = withDefaults(defineProps<{ adKey: AD_KEY_TYPE, bodyClass?: string, imgHeight?: number | undefined, imgWidth?: number | undefined, imgClass?: string }>(), {
  imgClass: 'w-full h-full',
  bodyClass: 'my-3',
  imgHeight: undefined,
  imgWidth: undefined,
})
const store = adStore()
const { adList } = storeToRefs(store)
const adData = computed(() => adList.value.get(props.adKey) || [])
</script>

<template>
  <n-carousel v-if="adData?.length" :autoplay="adData?.length > 1">
    <div
      v-for="v, i in adData"
      :key="i"
      :class="bodyClass"
      class="relative"
    >
      <video
        v-if="v.linkUrl?.match(/.mp4$/)"
        class="w-full min-h-167px"
        :poster="v.imgUrl"
        controls
        :src="v.linkUrl"
        loop
        autoplay
        muted
      />
      <NuxtLink
        v-else
        target="_blank"
        :to="v.linkUrl"
        block
      >
        <img
          :class="imgClass"
          loading="lazy"
          :alt="v.linkUrl"
          :src="imageTransform(v.imgUrl, { width: imgWidth, height: imgHeight })"
        >
        <h3 v-if=" v.title" class="title">
          {{ v.title }}
        </h3>
      </NuxtLink>
    </div>
  </n-carousel>
</template>

<style lang='scss' scoped>
.title {
  --uno: absolute bottom-0 left-0 w-full h-13 pt-3 leading-10  px-4 text-4 truncate z-2;
  --uno: bg-gradient-to-t from-black text-white font-bold;
}
</style>
